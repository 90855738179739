import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout.component";
import Header from "../components/header/Header.component";
import Section from "../components/layout/section/Section.component";
import CardList from "../components/card-list/CardList.component";
import InternalLink from "../components/card-list/internal-link/InternalLink.component";


const IndexPage = ({ data }) => {
  const page = {
    introduction: "Hello my name is Bartek",
    header: "I'm frontend developer",
    description:
      "My name is Bartłomiej and I am a front-end developer who specializes in React. I offer web design, development, and consultation services. I have been working as a front-end developer for 6 years now and have gained some experience.",
  };
  const cards = data.allMdx.nodes;
  return (
    <Layout>
      <Header page={page} />
      <Section title="blog">
        <CardList cards={cards} />
        <InternalLink path="/blog">All posts</InternalLink>
      </Section>
    </Layout>
  );
};
export default IndexPage;

export function Head() {
  return (
    <>
      <title>Bartłomiej Nowak - frontend developer | Home</title>
      <meta
        name="description"
        content="My name is Bartłomiej and I am a front-end developer who specializes in React. I offer web design, development, and consultation services. I have been working as a front-end developer for 6 years now and have gained some experience."
      />
    </>
  );
}

export const query = graphql`
  query posts {
    allMdx(limit: 6, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        fields {
          source
        }
        frontmatter {
          slug
          title
          date
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
